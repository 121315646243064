import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            meta: {
                darken: false
            },
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/game',
            name: 'Game',
            meta: {
                darken: true
            },
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            }
        },
        {
            path: '/claim',
            name: 'claim',
            component: () =>
            {
                return import( /* webpackChunkName: "claim" */ '@/app/pages/Claim' );
            }
        },
        {
            path: '/thanks',
            name: 'thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "claim" */ '@/app/pages/Thanks' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
