import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import scores from './modules/scores';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        scores
    },

    state: {
        mode: false,
        inGame: false
    },

    getters: {
        inGame: ({ inGame }) => inGame,
        mode: ({ mode }) => mode
    },

    mutations: {
        inGame: ( state, payload ) =>
        {
            state.inGame = payload;
        },
        mode: ( state, payload ) =>
        {
            state.mode = payload;
        }
    },

    actions: {
        inGame: ( context, payload ) =>
        {
            context.commit( 'inGame', payload );
        },
        mode: ( context, payload ) =>
        {
            context.commit( 'mode', payload );
        }
    }
});
