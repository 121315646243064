import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            score: 0
        }
    },

    getters: {
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            console.log( context.getters.all );
            context.commit( 'assign', payload );
            if( context.getters.all.id )
            {
                console.log( context.getters.all );
                return new EntryProxy()
                    .update( context.getters.all.id, context.getters.all )
                    .then( response =>
                    {
                        context.commit( 'assign', response );
                        return response;
                    })
                    .catch( error =>
                    {
                        return Promise.reject( error );
                    });
            }
            else
            {
                return new EntryProxy()
                    .create( context.getters.all )
                    .then( response =>
                    {
                        context.commit( 'assign', response );
                        return response;
                    })
                    .catch( error =>
                    {
                        return Promise.reject( error );
                    });
            }
        }
    }
};
