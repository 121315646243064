<template>
    <div class="main-layout">
        <header :class="{ inGame }">
            <h1 class="logo"><img src="/static/img/logo.png" alt=""></h1>
        </header>
        <div :class="{ content: true, bg: this.$route.meta.bg }">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    header {
        z-index: 1;
        position: relative;
    }

    .content.bg {
        background: transparent url('/static/img/score-bg.jpg') no-repeat top center;
        background-size: cover;
    }
</style>

<script>
    export default {
        components: {
        },

        computed: {
            members()
            {
                return this.$store.getters['mode'] === 'members';
            },
            inGame()
            {
                return this.$store.getters.inGame && !this.members;
            },
            color()
            {
                return this.inGame ? '#000000' : '#f8f8f8';
            },
            inverted()
            {
                return invertColor( this.color );
            }
        },

        updated()
        {
            console.log( this.$route.meta );
            this.$store.dispatch( 'inGame', this.$route.meta.darken );
        }
    };

    function invertColor( hex, bw )
    {
        if( hex.indexOf( '#' ) === 0 )
        {
            hex = hex.slice( 1 );
        }
        // convert 3-digit hex to 6-digits.
        if( hex.length === 3 )
        {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if( hex.length !== 6 )
        {
            throw new Error( 'Invalid HEX color.' );
        }
        let r = parseInt( hex.slice( 0, 2 ), 16 );
        let g = parseInt( hex.slice( 2, 4 ), 16 );
        let b = parseInt( hex.slice( 4, 6 ), 16 );
        if( bw )
        {
            return ( r * 0.299 + g * 0.587 + b * 0.114 ) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = ( 255 - r ).toString( 16 );
        g = ( 255 - g ).toString( 16 );
        b = ( 255 - b ).toString( 16 );
        // pad each with zeros and return
        return '#' + padZero( r ) + padZero( g ) + padZero( b );
    }

    function padZero( str, len )
    {
        len = len || 2;
        var zeros = new Array( len ).join( '0' );
        return ( zeros + str ).slice( -len );
    }
</script>
